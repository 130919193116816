import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import ISO6391 from 'iso-639-1';
import {  Divider, Tooltip, } from "antd";

const TwitchStreams = ({streamingDetail}) => {
  const [mainStream, setMainStream] = useState(null);

  const topStreams = useMemo(() => {
    const sortedStreaming = streamingDetail.sort((a,b) => b?.viewer_count - a?.viewer_count);
    if(sortedStreaming.length > 5){
      return sortedStreaming.slice(0,5)
    }else {
      return sortedStreaming;
    }
  }, [streamingDetail]);

  useEffect(() => {
    if(topStreams[0]){
      setMainStream(topStreams[0])
    }
  }, [topStreams]);

  const mainStreamUrl = useMemo(() => {
    if(mainStream){
      const {user_login} = mainStream;
      return `https://www.twitch.tv/${user_login}`;
    }
  }, [mainStream]);

  const additionStreams = useMemo(() => {
    if(topStreams && mainStream){
      const mainId = mainStream.id;
      return topStreams.filter(item => item.id !== mainId);
    }
    return []
  }, [topStreams, mainStream]);

  const getStreamImage = (item) => {
    const {thumbnail_url} = item;
    return thumbnail_url.replace('{width}', 224).replace('{height}', 149);
  }

  const switchStream = (id) => {
    const target = streamingDetail.find(item => item.id === id);
    if(target){
      setMainStream(target)
    }
  }

  const getLanguage = (code) => {
    const language = ISO6391.getName(code);
    return language;
  }

  return <Container style={{ display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
    <SubTitle style={{marginBottom:24}}>
      Twitch Streams
    </SubTitle>
    {
      mainStream && <>
      <ReactPlayer
    url={mainStreamUrl}
    width="944px"
    height="506px"
    playing={true}
    controls={true}
  />
  <StreamTitle>
    {mainStream?.title}
  </StreamTitle>
  <StreamerName>
    {mainStream?.user_name}
  </StreamerName>
  <Language>
    {
      getLanguage(mainStream?.language)
    }
  </Language>
  <Divider />
      </>
    }
    <SubTitle style={{fontSize:16, marginBottom:24}}>
      More Streams
    </SubTitle>
    <div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
    {
      additionStreams.map((item) => {
        return <div key={item.id} style={{display:'flex', width:'224px', flexDirection:'column', alignItems:'flex-start'}}>
          <Tooltip title="Click to watch this streamer">
          <img src={getStreamImage(item)} alt="streamImage" onClick={() => {
            switchStream(item.id);
          }}
          style={{cursor:'pointer'}}
          />
          </Tooltip>
          <StreamTitle style={{fontSize:14}}>
            {item?.title}
          </StreamTitle>
          <StreamerName style={{fontSize:12}}>
            {item?.user_name}
          </StreamerName>
          <Language>
          {
      getLanguage(item?.language)
    }
          </Language>
          </div>
      })
    }
    </div>
    </Container>

}

const Container = styled.div`
.ant-divider-horizontal {
  border-top: 1px solid #F72375;
}
`;


const SubTitle = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 26px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-decoration-line: underline;
`;

const StreamTitle = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin:16px 0px;

display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit the text to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; /* Display "..." for overflow text */
  white-space: normal; /* Ensure text wraps within the two lines */
`;

const StreamerName = styled.div`
color: #F72375;
font-family: SF Pro Display;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-decoration-line: underline;
margin-bottom:12px;
`;

const Language = styled.div`
width:auto;
padding: 3px 12px;
/* align-items: flex-start;
gap: 10px;
width:auto; */

color: #FFF;
font-family: SF Pro Display;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: normal;

border-radius: 100px;
background: #000;
`;

export default TwitchStreams