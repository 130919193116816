import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import PublicNavBar from "../../nav/publicNavBar";
import PrivateNavBar from "../../nav/privateNavBar";
import Firebase from "../../util/firebase";
import * as API from "../../util/api";
import Loading from "../../util/loading";
import { Mixpanel } from '../../util/mixpanel';
import moment from 'moment'
import EventBeforeStart from "./eventBeforeStart";
import EventAfterStart from "./eventAfterStart";
import {Helmet} from "react-helmet";
import axios from "axios";
import ChatBot from "../serviceBot";

const EventDetail = ({match}) => {
  const [event, setEvent] = useState(null);
  const [gameDetails, setGameDetails] = useState([]);
  const { loggedIn, profile, id: userId, vistorUserId} = useSelector((state) => state.user);

  useEffect(() => {
    const eventId =  match.params.id;
    let listener = null;
    const fetchEvent = async () => {
      listener = Firebase.firestore().collection("liveShoppingEvent").doc(eventId).onSnapshot((doc) => {
          const obj = {...doc.data(), eventId};
         setEvent(obj)
       })
    }

    fetchEvent()

    return () => {
      if(listener){
        listener();
      }
    }
  }, [match]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      // Customize the message you want to show in the confirmation dialog
     leaveEvent()
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const recordJoinEvent = async (eventId) => {
      try {
        const requestBody = {
          eventId,
        }
        if(profile && userId){
          requestBody.userId = userId;
          requestBody.userName = profile.first_name;
        }else if(vistorUserId) {
          requestBody.userId = vistorUserId;
          requestBody.userName = 'anonymous'
        }
        const url = API.JOIN_EVENT;
        await axios.post(url, requestBody);
      }catch(err){
        console.log(err);
      }
    }
    if(event?.eventId){
      recordJoinEvent(event.eventId);
      Mixpanel.track("[WEB] Join Live Shopping Event", {
        userId: userId?? vistorUserId,
        eventId:event.eventId
      })
    }
  }, [event?.eventId, profile, userId, vistorUserId]);


  const leaveEvent = async () => {
    if(event){
      try {
        const requestBody = {
          eventId:event?.eventId,
        }
        if(profile && userId){
          requestBody.userId = userId;
        }else if(vistorUserId) {
          requestBody.userId = vistorUserId;
        }
        const url = API.LEAVE_EVENT;
        await axios.post(url, requestBody);
      }catch(err){
        console.log(err);
      }
    }
  }



  useEffect(() => {
    async function fetchDocsByIds(docIds) {
      try {
        const docPromises = docIds.map(async (docId) => {
          const docRef = Firebase.firestore().collection('partnerGames').doc(docId); // Replace 'yourCollectionName' with your collection name
          const docSnapshot = await docRef.get();
          
          if (docSnapshot.exists) {
            const docData = docSnapshot.data();

            // Exclude the 'codesForSale' field if it exists
            if (docData.hasOwnProperty('codesForSale')) {
              delete docData.codesForSale;
            }
    
            // Exclude the 'availableCodes' field if it exists
            if (docData.hasOwnProperty('availableCodes')) {
              delete docData.availableCodes;
            }
    
            return {gameId:docId, ...docData}; // Return the document data
          } else {
            throw new Error(`Document with ID '${docId}' does not exist.`);
          }
        });
    
        const docs = await Promise.all(docPromises);
        return docs;
      } catch (error) {
        console.error('Error fetching documents:', error);
        throw error;
      }
    }
    const fetchGames = async () => {
      if(event && event.games) {
        const gameIds = event.games.map((item) => {
          return item.gameId
        });
        const result = await fetchDocsByIds(gameIds);
        setGameDetails(result)
      }
    }
    fetchGames()
  }, [event]);

  const keywords = useMemo(() => {
    const gameNames =  gameDetails.map(game => game.gameName).join(",");
    return gameNames;
  }, [gameDetails])

  return <Container>
   <Helmet>
        <title>Live Shopping Event - ArcadeQuest</title>
        <meta name="keywords" content={keywords} />
    </Helmet>
  {loggedIn ? <PrivateNavBar isDarkMode={true}/> : <PublicNavBar isDarkMode={true}/>}
  {
    !event && <div style={{width:'1340px', margin:'36px auto'}}>
      <Loading />
      </div>
  }
  {
    event && event.status === 0 && <EventBeforeStart gameDetails={gameDetails} event={event} leaveEvent={leaveEvent}/>
  }
  {
    event && event.status === 1 && <EventAfterStart gameDetails={gameDetails} event={event} leaveEvent={leaveEvent}/>
  }
  <ChatBot />
  </Container>
}

const Container = styled.div`
  width: 100%;
  background:#1C1D20 0% 0% no-repeat padding-box;
  min-height:calc(100vh);
  padding-top:78px;
  overflow-y:auto;
  position:relative;

  .ant-divider-horizontal {
    border-top: 1px solid #F72375;
  }
`;

export default withRouter(EventDetail)