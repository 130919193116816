import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Loading from "../util/loading";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import moment from "moment";
import { List } from "antd";
import * as TransTypes from "../util/transConstant";
//firebase
import Firebase from "../util/firebase";

class Billing extends Component {
  constructor() {
    super();
    this.listener = null;
  }

  state = {
    loading: false,
    history: [],
    sort: "asc",
  };

  componentDidMount() {
    this.setState({
      loading: true,
    });
    this.fetchHistory();
    this.setState({
      loading: false,
    });
  };

  componentWillUnmount = () => {
    if(this.listener){
      this.listener();
    }
  }

  fetchHistory = () => {
    const { user } = this.props;
    const { profile } = user;
    const { transId } = profile;
    this.listener = Firebase.firestore()
      .collection("transactions")
      .doc(transId)
      .onSnapshot((doc) => {
        const history = doc.data().history;
        this.setState({
          history,
        });
      }, (err) => {
        console.log(err);
      });
  };

  changeSort = (value) => {
    this.setState({
      sort: value,
    });
  };

  render() {
    const { loading, history, sort } = this.state;
    const pagination = {
      pageSize: 10,
      size: "small",
    };
    let trans = history;

    if (sort === "dec") {
      let array = [];
      for (let i = history.length - 1; i >= 0; i--) {
        array.push(history[i]);
      }
      trans = array;
    } else {
      trans = history;
    }

    const data = [];
    if (trans.length >= 1) {
      for (let i = 0; i < trans.length; i++) {
        const {
          amount_type,
          currentBalance,
          message,
          transAmount,
          type,
          time,
        } = trans[i];
        let amountText = "";
        if (amount_type === TransTypes.INCREASE_TOKEN) {
          amountText = "+" + transAmount;
        } else {
          amountText = "-" + transAmount;
        }
        let timeText = "";
        if (moment(time).format().toString() === "Invalid date") {
          timeText = time;
        } else {
          timeText = moment(time).format("l  H:mm");
        }
        const item = {
          time: timeText,
          amount: amountText,
          type,
          message,
          currentBalance,
        };
        data.unshift(item);
      }
    }

    const renderItem = (data) => {
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: 41,
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "20%",
              textAlign: "center",
              font: "normal normal 600 15px SF Pro Rounded",
              color: "#F72375",
            }}
          >
            {data.amount}
          </div>
          <div
            style={{
              width: "35%",
              textAlign: "center",
              font: "normal normal 600 15px SF Pro Rounded",
              color: "white",
            }}
          >
            {data.type}
          </div>
          <div
            style={{
              width: "20%",
              textAlign: "center",
              font: "normal normal 600 15px SF Pro Rounded",
              color: "white",
            }}
          >
            {data.currentBalance}
          </div>
          <div
            style={{
              width: "25%",
              textAlign: "center",
              font: "normal normal 600 15px SF Pro Rounded",
              color: "white",
            }}
          >
            {data.time}
          </div>
        </div>
      );
    };

    return loading ? (
      <Container>
        <Loading />
      </Container>
    ) : (
      <Container>
        <Content>
          <SubTitle style={{color:'white'}}>Coins Transactions</SubTitle>
          <Trans>
            <Header>
              <HeaderItem style={{ width: "20%" }}>gainer/loss</HeaderItem>
              <HeaderItem style={{ width: "35%" }}>type</HeaderItem>
              <HeaderItem style={{ width: "20%" }}>coins balance</HeaderItem>
              <HeaderItem style={{ width: "25%" }}>
                Time
                {sort === "asc" ? (
                  <DownOutlined
                    style={{ cursor: "pointer", marginLeft: 2 }}
                    onClick={() => this.changeSort("dec")}
                  />
                ) : (
                  <UpOutlined
                    style={{ cursor: "pointer", marginLeft: 2 }}
                    onClick={() => this.changeSort("asc")}
                  />
                )}
              </HeaderItem>
            </Header>
            <List
              dataSource={data}
              pagination={pagination}
              renderItem={(item) => (
                <List.Item key={item.id} style={{ padding: 0 }}>
                  {renderItem(item)}
                </List.Item>
              )}
            ></List>
          </Trans>
        </Content>
      </Container>
    );
  }
}

const Container = styled.div`
  width: 726px;
  @media (min-width: 1480px) {
    width: 826px;
  }
  .ant-pagination-item a {
    color:white;
  }
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color:white;
  }

  .ant-pagination-item-active a {
    color:#1890ff !important;
  }
`;

const Title = styled.span`
  display: block;
  text-align: left;
  font: normal normal bold 18px/21px SF PRO, serif;
  letter-spacing: 0px;
  color: #ee486d;
  margin-bottom: 43px;
`;

const Content = styled.div`
  border-top: 1px solid #7b7a7a;
  padding-top: 48px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 96px;
`;

const SubTitle = styled.div`
  text-align: left;
  font: normal normal 600 17px/53px SF PRO, serif;
  letter-spacing: 0px;
  color: #545454;
`;

const Trans = styled.div`
  width: 100%;
  background: #000 0% 0% no-repeat padding-box;
  border: 1px solid #70707052;
  border-radius: 5px;
  padding: 18px 24px 12px 24px;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const HeaderItem = styled.div`
  font: normal normal bold 15px/28px SF PRO, serif;
  letter-spacing: 0px;
  color: #f72375;
  text-transform: uppercase;
  text-align: center;
`;

const mapStateToProps = ({ user, matches }) => ({ user, matches });

export default connect(mapStateToProps)(Billing);
