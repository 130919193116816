import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import PublicNavBar from "../../nav/publicNavBar";
import PrivateNavBar from "../../nav/privateNavBar";
import Firebase from "../../util/firebase";
import CurrentGames from "./currentGames";
import ChatBot from "../serviceBot";

const LiveShopping = ({history}) => {
  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);
  const [games, setGames] = useState([]);

  useEffect(() => {
    const listener = Firebase.firestore()
    .collection("partnerGames")
    .where("promoted", "==", true)
    .onSnapshot(querySnapshot => {
      let list = [];
      querySnapshot.forEach(function (doc) {
        const data = doc.data();
        const codesForSaleLength = data.codesForSale ? data.codesForSale.length : 0;
        const availableCodesLength = data.availableCodes ? data.availableCodes.length : 0;
        const obj = {
         ...data,
         gameId: doc.id,
         codesForSale: codesForSaleLength,
         availableCodes: availableCodesLength,
        };
        list.push(obj)
      });
      setGames(list)
    })
    return () => {
      if(listener){
        listener();
      }
    }
  }, [])
  return <Container>
    {loggedIn ? <PrivateNavBar isDarkMode={true} activeTab="currentDeals"/> : <PublicNavBar isDarkMode={true} activeTab="currentDeals"/>}
    <Top>
        <TopText>
          <Title>
          Current deals
          </Title>
          <TopContext>
          View all our featured deals. You can buy these discounted games at anytime but during our live event’s there will be even bigger discounts. 
          </TopContext>
        </TopText>
      </Top>
    <Content>
      <CurrentGames games={games} />
    </Content>
    <ChatBot />
  </Container>
}

const Container = styled.div`
  width: 100%;
  background:#1C1D20 0% 0% no-repeat padding-box;
  min-height:calc(100vh);
  padding-top:78px;
  overflow-y:auto;
  position:relative;
`;

const Content = styled.div`
width:1260px;
margin:0px auto 48px;
`;
const Top = styled.div`
width: 100%;
height: 261px;
flex-shrink: 0;
margin-bottom:36px;
background-image: url("/assets/liveShopping.png");
background-size: cover;
background-position: center;
border: 1px solid transparent; 
`;

const TopText = styled.div`
width:1260px;
margin:36px auto;
`;

const TopContext = styled.div`
color: #FFF;
font-family: SF Pro;
font-size: 16px;
font-style: normal;
font-weight: 590;
line-height: normal;
width:400px;
margin-top:24px;
`;

const Title = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
`;

export default withRouter(LiveShopping)