import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import InfluencerEntry from "./influencerEntry";
import PublisherEntry from "./publisherEntry";
// game live shopping
import LiveShoppingGames from "./liveShopping";
import LiveShoppingEvent from "./liveShoppingEvent";

const HomePageEntry = () => {
  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);
  const role = useMemo(() => {
    if(loggedIn && profile){
      if(profile.role === 'influencer'){
        return 'influencer'
      }else if(profile.role === 'publisher'){
        return 'publisher'
      }
    }
    return 'player'
  }, [loggedIn, profile]);

  return <>
  {
    role === 'player' && <LiveShoppingEvent />
  }
  {
    role === 'influencer' && <InfluencerEntry />
  }
  {
    role === 'publisher' && <PublisherEntry />
  }
  </>
}

export default HomePageEntry;