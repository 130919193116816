import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import ReactPlayer from "react-player";
import Countdown from "./component/countdown";
import moment from "moment";
import BeforeStartGameCard from "./component/beforeStartGameCard";
import axios from "axios";
import * as API from "../../util/api";
import { useSelector } from "react-redux";


const EventBeforeStart = ({gameDetails, event, history, leaveEvent}) => {

  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);


  const customStyle = {
    color: '#FFF',
    textAlign: 'left',
    fontFamily: 'SF Pro Display',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight:'16px'
  };
  return <Container>
    
    <div style={{display:'flex'}}>
    <div style={{width:'50%', alignItems:'center', borderRight:'2px solid #616161', paddingTop:36}}>
        <Title>
          <img src="/assets/back.svg" alt="back" onClick={() => {
            leaveEvent()
                history.push(`/liveShoppingEvents`)
              }} style={{cursor:'pointer', marginRight:12}}/> 
          {event?.title}
        </Title>
        <ReactPlayer
        url={event?.video}
        width="328px"
        height="674px"
        playing={true}
        controls={true}
      />
    </div>
   <div style={{marginLeft:'68px', paddingTop:'36px', width:412}}>
     <LogoTitle>
       <img src="/assets/arcadequest_steam_logo.svg" style={{
        width:20,
        height:20,
        marginRight:12
       }} 
       alt="logo"
       />
       ArcadeQuest
       </LogoTitle>
     <div style={{display:'flex', width:'412px'}}>
        <Countdown endDate={event.startTime} style={customStyle} />
        <img src="/assets/event_countdown.svg" style={{
          height:14,
          marginLeft:12,
        }} alt="countdown"/>
        </div>
        <div style={{display:'flex', alignItems:'flex-end', marginBottom:24}}>
          <Time>
          {moment(event.startTime).format('MMMM D, YYYY @ h:mma')}
          </Time>
          <div style={{marginLeft:'auto'}}>
            <Time style={{fontSize:12, marginBottom:8}}>
              More than
            </Time>
            <Discount>
              -25%
            </Discount>
          </div>
        </div>
        {
          gameDetails.map((item) => <BeforeStartGameCard game={item} />)
        }
   </div>
    </div>
  </Container>
}

const Container = styled.div`
width:960px;
margin:0px auto;
`;


const Title = styled.div`
color: #FAFAFA;
font-family: SF Pro Display;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

const Time = styled.div`
color: #FFF;
text-align: right;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: normal;
`;

const Discount = styled.div`
color: #FFF;
text-align: center;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 29px;

width: 54px;
height: 29px;
flex-shrink: 0;

border-radius: 2px;
background: #F72375;
`;

const LogoTitle = styled.div`
margin-bottom:12px;
color: #FFF;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: normal;
`;

export default withRouter(EventBeforeStart)