import React, { useState, useEffect,useMemo, useRef} from 'react';
import styled from 'styled-components';
import { Input, Button,message, Skeleton} from 'antd';
import axios from "axios";
import * as API from "../../util/api";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { CloseOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";

const ChatBox = ({chat, closeChat, history}) => {

  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);

  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const mesRef = useRef(null);

  const displayMessages = useMemo(() => {
    if(chat && chat.messages){
      if(chat.type==="game"){
        chat.messages[0]= 
          {
            role:'system',
            content:`Hello! I'm your Game Assistant, and I'm here to assist you with any questions or information you need about ${chat?.gameName}. Feel free to ask, and let's explore ${chat?.gameName} together!`
          }
        
      }else {
        chat.messages[0] = 
          {
            role:'system',
            content:`Hello, I'm your Game Assistant! Ask about video games, tips, and ArcadeQuest deals. For game recommendations, strategies, or info on your favorites, I'm here. Let's explore the gaming world together. Ask your questions, and let's level up your gaming experience!`
          }
        }
      return chat.messages;
    }else {
      if(chat.type==="game"){
        return [
          {
            role:'system',
            content:`Hello! I'm your Game Assistant, and I'm here to assist you with any questions or information you need about ${chat?.gameName}. Feel free to ask, and let's explore ${chat?.gameName} together!`
          }
        ]
      }else {
        return [
          {
            role:'system',
            content:`Hello, I'm your Game Assistant! Ask about video games, tips, and ArcadeQuest deals. For game recommendations, strategies, or info on your favorites, I'm here. Let's explore the gaming world together. Ask your questions, and let's level up your gaming experience!`
          }
        ]
      }
      
    }
  }, [chat]);

  console.log(displayMessages)

  useEffect(() => {
    scrollToBottom();
}, [displayMessages]);

const scrollToBottom = () => {
  if (mesRef.current) {
    mesRef.current.scrollTop = mesRef.current.scrollHeight;
  }
};

const debounceSendMessage = debounce(() => {
  sendMessage();
}, 500);

const debounceClearChat = debounce(() => {
  clearChat()
}, 500)

const clearChat = async () => {
  if(chat.chatId){
    try {
      const requestBody = {
        chatId:chat.chatId,
        userId
      }
      const url = API.GAME_ASSISTANT_CLEAR_CHAT;
      const res = await axios.post(url, requestBody);
      if(res.status){
        message.success("Your chat history is clear!")
      }else {
        message.error("Failed to clear chat history, please try again")
      }
    }catch(err){
      message.error("Failed to clear chat history, please try again")
    }finally{
      setLoading(false)
    }
  }
}

const sendMessage = async () => {
  if(chat.type === 'game'){
      if(chat.chatId){
        try {
          const requestBody = {
            message:input,
            chatId:chat.chatId,
            userId
          }
          const url = API.GAME_ASSISTANT_NEW_MESSAGE;
          const res = await axios.post(url, requestBody);
          if(res.status === 200){
            setInput("");
          }else {
            message.error("Failed to send message, please try again")
          }
        }catch(err){
          console.log(err)
          message.error("Failed to send message, please try again")
        }finally{
          setLoading(false);
        }
      }else {
        try {
          const requestBody = {
            message:input,
            gameId:chat.gameId,
            userId
          }
          const url = API.GAME_ASSISTANT_NEW_CHAT;
          const res = await axios.post(url, requestBody);
          if(res.status === 200){
            setInput("");
          }else {
            message.error("Failed to send message, please try again")
          }
        }catch(err){
          message.error("Failed to send message, please try again")
        }finally{
          setLoading(false);
        }
  }
      }else if(chat.type === 'platform') {
        if(chat.chatId){
          try {
            const requestBody = {
              message:input,
              chatId:chat.chatId,
              userId
            }
            const url = API.GAME_ASSISTANT_NEW_MESSAGE;
            const res = await axios.post(url, requestBody);
            if(res.status === 200){
              setInput("");
            }else {
              message.error("Failed to send message, please try again")
            }
          }catch(err){
            console.log(err)
            message.error("Failed to send message, please try again")
          }finally{
            setLoading(false);
          }
        }else {
          try {
            const requestBody = {
              message:input,
              modelId:chat.modelId,
              userId
            }
            const url = API.GENERAL_ASSISTANT_NEW_CHAT;
            const res = await axios.post(url, requestBody);
            if(res.status === 200){
              setInput("");
            }else {
              message.error("Failed to send message, please try again")
            }
          }catch(err){
            message.error("Failed to send message, please try again")
          }finally{
            setLoading(false);
          }
    }
      }
  
    }

  return <Container style={{width:'450px', height:'500px'}}>
    <div style={{display:'flex', height:36, width:'100%', alignItems:'center', padding:'0 12px',
    borderBottom:'1px solid #383838'
  }}>
      <GameName>
        {chat?.gameName || chat?.name}
      </GameName>
      <div style={{marginLeft:'auto', display:'flex'}}>
        {
          chat?.chatId && <ClearButton style={{marginRight:16}} onClick={() => {
           if(!loading){
            setLoading(true);
            debounceClearChat()
           }
          }}>
            Clear Chat
          </ClearButton>
        }
        {
          chat.type === 'game' &&  <BuyButton style={{marginRight:16}} onClick={() => {
            const gameId = chat?.gameId;
            history.push(`/liveShoppingGame/${gameId}`)
          }}>
            Buy Now
          </BuyButton>
        }
        {
          chat.type === 'game' && <DetailButton style={{marginRight:16}} onClick={() => {
            const gameId = chat?.gameId;
            history.push(`/liveShoppingGame/${gameId}`)
         }}>
           Details
         </DetailButton>
        }
        <CloseOutlined style={{color:'white'}} onClick={() => {
          closeChat()
        }}/>
      </div>
    </div>
    <MessageBox ref={mesRef}>
    {displayMessages.map((item) => {
      const {role, content} = item;
      if(role === 'system'){
        return <AssistantMessage>
          {content}
        </AssistantMessage>
      }else if (role === 'assistant'){
        return <AssistantMessage>
          {content}
        </AssistantMessage>
      }else {
        return <UserMessage>
          {content}
        </UserMessage>
      }
    })}
    </MessageBox>
    {
      loggedIn ? <>
        {
      loading ? <PlaceholderContainer>
         We are working on it... Please hold on!
        </PlaceholderContainer> : <div style={{display:'flex'}}>
      <StyledInput placeholder='Type In Your question Here' onChange={(e) => {
        setInput(e.target.value)
      }
    }
    onPressEnter={() => {
      if(!loading){
        setLoading(true)
        debounceSendMessage()
      }
    }}
      value={input}
      suffix={
        <img src="/assets/chat_send_icon.svg" alt="send" style={{width:24, height:24}}
        onClick={() => {
          if(!loading && input !== ""){
            setLoading(true)
            debounceSendMessage()
          }
        }}
        />
      }
      showCount 
      maxLength={96} 
      />
    </div>
    }
      </> : <>
        <PlaceholderContainer style={{background:"#F72375", cursor:'pointer'}}
        onClick={() => {
          history.push("/login")
        }}
        >
          Log in First to Start a Chat!
        </PlaceholderContainer>
      </>
    }
    
  </Container>
}

const Container = styled.div`
.ant-input-affix-wrapper > .ant-input {
  background: #1C1D20;

color: #FFF;
}

.ant-input-show-count-has-suffix {
  color:white;
}
`;

const StyledInput = styled(Input)`
  height: 38px;
  width:426px;
  border-radius: 5px;
background: #1C1D20;
border:none;

color: #FFF;
font-family: SF Pro Display;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: normal;
margin:0px auto;
`;

const MessageBox = styled.div`
height:408px;
width:100%;
overflow-y: auto;
display:flex;
flex-direction:column;
padding:12px 12px 0 12px;
margin-bottom:12px;
`;

const AssistantMessage = styled.div`
max-width:80%;
padding:6px;
border-radius:10px;
background: #E9E9EB;
align-self: flex-start; 
margin-bottom:8px;
color: #000;

font-family: SF Pro Display;
font-size: 14px;
`;

const UserMessage = styled.div`
max-width:80%
padding:4px;
border-radius:10px;
background: #3CABFC;
color: white;
align-self: flex-end; 
margin-bottom:8px;
padding:6px;

font-family: SF Pro Display;
font-size: 14px;
`;

const GameName = styled.div`
color: #FFF;
font-family: "SF Pro Display";
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
`;

const BuyButton = styled.div`
width: 70px;
height: 20px;
flex-shrink: 0;
border-radius: 4px;
background: #F72375;
color: #FFF;
font-family: SF Pro Display;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
text-align:center;
cursor:pointer;
`;

const DetailButton = styled.div`
width: 70px;
height: 20px;
flex-shrink: 0;
border-radius: 4px;
background: #16B9FF;
color: #FFF;
font-family: SF Pro Display;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
text-align:center;
cursor:pointer;
`;

const ClearButton = styled.div`
width: 70px;
height: 20px;
flex-shrink: 0;
border-radius: 4px;
background: #faad14;
color: #FFF;
font-family: SF Pro Display;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
text-align:center;
cursor:pointer;
`;

const PlaceholderContainer = styled.div`
height: 38px;
  width:426px;
  border-radius: 5px;
background: #1C1D20;
border:none;
margin:0px auto;
color:white;
font-family: SF Pro Display;
text-align:center;
line-height:38px;
`;

export default withRouter(ChatBox);