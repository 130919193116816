import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

const CurrentGames = ({games, history}) => {
  const getImage = (item) => {
    const image = item.detailOnTwitch?.box_art_url;
    if(image){
      const updatedUrl = image.replace('{width}', 144).replace('{height}', 192);
      return updatedUrl;
    }
    return ""
  }
  const genreList = (item) => {
    let genres = [];

    if (Array.isArray(item.genre)) {
      genres = item.genre.map((tag) => {
        console.log(tag);
        return tag;
      });
    } else if (typeof item.genre === 'string') {
      genres = [item.genre];
    }
    return genres;
  }

  const getDiscountPercent = (item) => {
    if(item && item.original_price && item.promote_price){
      const diff = item.original_price - item.promote_price;
      const percent = Math.round((diff/item.original_price)*100);
      return percent
    }
    return null;
  }  
  return <Container>
    {
      games.map((item) => {
        return <div key={item.gameId} style={{display:'flex', width:'540px', height:'192px'}}>
          <img src={getImage(item)} alt="twitch_url" style={{borderRadius:7, marginRight:16}}/>
          <div style={{display:'flex', justifyContent:'space-between', flexDirection:'column'}}>
            <Name>
              {item.name}
            </Name>
            <div style={{display:'flex'}}>
            {
              genreList(item).map((tag) => {  
                return <Tag>
                  {tag}
                </Tag>
              })
            }
            </div>
            <div style={{display:'flex', margin:'8px 0px', alignItems:'center'}}>
                <span style={{textDecoration:'line-through', display:'inline-block', marginRight:12, fontSize:14,
                 color: "rgba(255, 255, 255, 0.73)"
                }}>
                 ${item?.original_price}
                  </span> 
                  <Price>
                  ${item?.promote_price}
                  </Price>
                  <Discount>
                    -{getDiscountPercent(item)} %
                  </Discount>
                </div>

            <ViewButton onClick={() => {
              history.push(`/liveShoppingGame/${item.gameId}`)
            }}>
              View Streams
            </ViewButton>
          </div>
        </div>
      })
    }
  </Container>
}

const Container = styled.div`
width:100%;
display:grid;
grid-template-columns: repeat(2, 540px);
grid-row-gap:32px;
grid-column-gap:80px;
margin:32px 0px 64px;
`;

const Name = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
`;

const Tag = styled.div`
border-radius: 4px;
background: #000;
color: #FFF;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
padding:4px 8px;
margin-right:8px;
`;

const Price = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
`;

const ViewButton = styled.div`
display: flex;
width: 215px;
padding: 16px 10px 16px 16px;
justify-content: center;
align-items: center;
gap: 16px;
border-radius: 8px;
border: 1px solid #F72375;
background: #000;
cursor:pointer;

color: #F72375;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: uppercase;
`;

const Discount = styled.div`
display: inline-flex;
padding: 2px 8px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 5px;
background: #F72375;
margin-left:16px;

color: #FFF;
text-align: center;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

export default withRouter(CurrentGames);
