import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import PublicNavBar from "../../nav/publicNavBar";
import PrivateNavBar from "../../nav/privateNavBar";
import Firebase from "../../util/firebase";
import Loading from "../../util/loading";
import axios from "axios";
import * as API from "../../util/api";
import qs from 'query-string';
import { useSelector } from "react-redux";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {message} from 'antd';

const EventPaymentStatus = ({match, location, history}) => {
  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);

  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [game, setGame] = useState(null);
  const [eventId, setEventId] = useState(null);
  useEffect(()=> {

    const fetchGame = async (gameId) => {
      const gameRef = Firebase.firestore().collection("partnerGames").doc(gameId);
      const gameData= (await gameRef.get()).data();
      setGame({...gameData, gameId})
    }
    const fetchOrder = async (orderId) => {
      const orderRef = Firebase.firestore().collection("liveShoppingOrder").doc(orderId);
      const orderData= (await orderRef.get()).data();
      setOrder(orderData)
    }

    const searchParams = qs.parse(location.search);
    const sessionId = searchParams.sessionId;
    const getSessionStatus = async () => {
      const url = API.LIVESHOPPING_SESSION_STATUS;
      const requestBody = {
      sessionId,
    };
    try {
      const res = await axios.post(url, requestBody);
      if (res.status === 200) {
        if (res.data.sessionStatus.payment_status === "paid") {
          const {metadata} = res.data.sessionStatus;
          const {orderId, gameId, eventId} = metadata;
          await fetchGame(gameId);
          await fetchOrder(orderId)
          setEventId(eventId);
          setLoading(false);
        } else {
          setLoading(false)
        }
      }
    } catch (err) {
      console.log(err)
      setLoading(false);
    }
    }
    if(!loggedIn){
      history.pushState("/")
    }else {
      getSessionStatus()
    }
  }, [location, loggedIn, history]);

  const orderStatus = useMemo(() => {
    if(order){
      return order.status
    }
    return 0;
  }, [order]);

  const getImage = (item) => {
    const image = item?.detailOnTwitch?.box_art_url;
    if(image){
      const updatedUrl = image.replace('{width}', 144).replace('{height}', 192);
      return updatedUrl;
    }
    return ""
  }

  return <Container>
    {loggedIn ? <PrivateNavBar isDarkMode={true}/> : <PublicNavBar isDarkMode={true}/>}
    <Content>
      {loading ? <Loading /> : <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
        <Top>
          <img src="/assets/back.svg" alt="back" onClick={() => {
            history.push(`/liveShoppingEvent/${eventId}`)
          }} style={{cursor:'pointer', marginRight:12}}/> BACK TO STREAM
        </Top>
        {
          orderStatus === 1 && <>
           <Result>
        Congratulations on your purchase of
        </Result>
        <Name>
          {game?.name}
        </Name>
        <Steam>
          STEAM KEY <img src="/assets/steamGame.svg" alt="back"/>
        </Steam>
        <CopyToClipboard
                  text={order?.code}
                  onCopy={() =>
                    message.success("Code is copied")
                  }
                >
           <KeyBox>
            {order?.code} <img src="/assets/copy.svg" alt="back" style={{marginLeft:12, cursor:'pointer'}}/>
        </KeyBox >
         </CopyToClipboard>
         <img src={getImage(game)} alt="twitch_url" style={{borderRadius:7, marginRight:16}}/>
          </>
        }
      </div>}
    </Content>
  </Container>
}

const Container = styled.div`
  width: 100%;
  background:#1C1D20 0% 0% no-repeat padding-box;
  min-height:calc(100vh);
  padding-top:78px;
  overflow-y:auto;
  position:relative;
`;

const Content = styled.div`
width:1260px;
margin:48px auto 48px;
`;

const Top = styled.div`
color: #F72375;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: uppercase;
margin-bottom:16px;
`;

const Result = styled.div`
color: #FFF;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
margin-bottom:16px;
`;

const Name = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: uppercase;
margin-bottom:16px;
`;

const Steam = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
margin-bottom:16px;
`;

const KeyBox = styled.div`
border-radius: 10px;
background: #000;
width: auto;
height: 44px;
flex-shrink: 0;
padding:0 16px;

color: #FFF;
font-family: SF Pro Display;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 44px;
margin-bottom:16px;
`;

export default withRouter(EventPaymentStatus);