import React from 'react';
import styled from 'styled-components';
import {Link } from "react-router-dom";
import {
  DownloadOutlined
} from '@ant-design/icons';

const Sidebar = ({currentSelect}) => {
  return <Container>
    <Link to="/myAccount/information" >
    <Option selected={currentSelect === 'information'}>
        {
          currentSelect === "information" ? <Icon src="/assets/account-info-write.png" alt="icon"/> : <Icon src="/assets/account-info.png" alt="icon"/>
        }
          <Title>
              Information
          </Title>
       </Option>
    </Link>
    <Link to="/myAccount/transaction" >
      <Option selected={currentSelect === 'transaction'}>
        {
          currentSelect === "transaction" ? <Icon src="/assets/account-billing-write.png" alt="icon"/> : <Icon src="/assets/account-billing.png" alt="icon"/>
        }
          <Title>
              Transaction
          </Title>
       </Option>
    </Link>
    <Link to="/myAccount/orders" >
      <Option selected={currentSelect === 'orders'}>
        {
          currentSelect === "orders" ? <DownloadOutlined style={{
            width:18,
            height:18,
            color:'white',
            marginRight:12
          }}/> : <DownloadOutlined style={{
            width:18,
            height:18,
            color:'gray',
            marginRight:12
          }}/>
        }
          <Title>
              Orders
          </Title>
       </Option>
    </Link>
  </Container>
}

const Container = styled.div`
  background: #1C1D20;
  width: 225px;
  position: fixed;
  inset: 0%;
  z-index: 20;
  display: flex;
  flex-direction: column;
  padding-top: 108px;
  padding-bottom:64px;
  font-family:SF Pro Display;
`;

const Icon = styled.img`
width:18px;
height:18px;
margin-right:12px;
`;

const Title = styled.div`
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
color: #FFF;
`;

const Option = styled.div`
display:flex;
margin-bottom:24px;
align-items:center;
padding:12px 0 12px 32px;
${p => p.selected && `background-color: #F72375;`}
`;

export default Sidebar;

