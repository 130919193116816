import React, { useState, useEffect,useMemo } from 'react';
import { Divider,  } from "antd";
import styled from 'styled-components';
import { useSelector } from "react-redux";
import Firebase from "../../util/firebase";
import ChatBox from './chatBox';


const ChatBot = () => {
  const [allChat, setAllChat] = useState([]);
  const [bots, setBots] = useState([]);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [currentBot, setCurrentBot] = useState(null);

  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);

  const currentMessageUsage = profile.bot_messages??0;

  useEffect(() => {
    let listener = null;
    const fetchChat = async () => {
      if(userId){
        listener = Firebase.firestore()
        .collection("game_assistant_chat")
        .where("userId", "==", userId)
        .where("status", "==", 1)
        .onSnapshot(querySnapshot => {
          let chats = [];
          querySnapshot.forEach(function (doc) {
            const obj = {
              ...doc.data(),
             chatId: doc.id,
            };
            chats.push(obj);
          });
          setAllChat(chats);
        },
        error => {
          console.log(error);
        }
    )
      }
      
  }
  fetchChat();
  return () => {
    if(listener){
      listener();
    }
  }
  }, [userId]);

  useEffect(() => {
    let listener = null;
    const fetchBots = async () => {
      listener = Firebase.firestore()
        .collection("game_assistant_bot")
        .onSnapshot(querySnapshot => {
          let bots = [];
          querySnapshot.forEach(function (doc) {
            const obj = {
              ...doc.data(),
             botId: doc.id,
            };
            bots.push(obj);
          });
          setBots(bots);
          const defaultBot = bots.find(item => item.type === 'platform')
          if(defaultBot){
            setCurrentBot(defaultBot.botId)
          }else {
            const defaultBotId = bots[0].botId;
          setCurrentBot(defaultBotId);
          }
        },
        error => {
          console.log(error);
        }
    )
  }
  fetchBots()
  return () => {
    if(listener){
      listener();
    }
  }
  }, [userId]);

  
  const serviceList = useMemo(() => {
    const list = bots.map((item) => {
      const chat = allChat.find((chat) => chat.modelId === item.modelId)
      if(chat){
        return {
          ...item,
          messages:chat.messages,
          chatId:chat.chatId
        }
      }else {
        return {
          ...item,
  
        }
      }
    });

    list.sort((a, b) => {
      if (a.type === 'platform' && b.type !== 'platform') {
        return -1; // a comes first
      }
      if (a.type !== 'platform' && b.type === 'platform') {
        return 1; // b comes first
      }
      return 0; // no change in order
    });
    return list;
  }, [bots, allChat]);

  const selectedBot = useMemo(() => {
    if(currentBot){
      return serviceList.find(item => item.botId === currentBot);
    }
  }, [serviceList, currentBot]);


  // Style for the chat popup
  const chatPopupStyle = {
    position: 'fixed',
    bottom: '78px', // Position above the button
    right: '24px',
    width: 'auto',
    height: '500px',
    backgroundColor: '#000',
    borderRadius: '10px',
    display: isChatOpen ? 'block' : 'none', // Display based on state
  };

  return (
    <div>
      <img src="/assets/chat_bot_icon.svg" alt="chat_bot" style={{ position: 'fixed', bottom: 20, right: 20, 
      zIndex: 1000,height:50, width:50, cursor:'pointer'
    }}
    onClick={() => {
      setIsChatOpen(!isChatOpen)
    }}
    /> 

      <Container style={chatPopupStyle}>
        <div style={{display:'flex', width:'100%', height:'100%'}}>
          <div>
          <div style={{width:144, height:'468px', background: '#2B2D31', padding:'4px 8px', borderRadius: "10px 0px 0px 0px"}}>
            {
              serviceList.map((item) => {
                return <>
                  <ChatName active={currentBot === item.botId} 
                  isPlatform={item.type === 'platform'}
                  onClick={() => {
                    setCurrentBot(item.botId)
                  }}>
                   {item.gameName || item.name}
                  </ChatName>
                  {/* <Divider /> */}
                </>
              })
            }
          </div>
          <MessagesCount style={{color:'white'}}>
            Usage Limit: {currentMessageUsage}/100
          </MessagesCount>
          </div>
          {
            currentBot && <ChatBox chat={selectedBot} closeChat={() => {
              setIsChatOpen(false);
            }}
            key={currentBot}
            />
          }
        </div>
      </Container>
    </div>
  );
};


const Container = styled.div`
.ant-divider-horizontal {
  border-top: 1px solid rgb(224, 224, 224);
  margin:0px;
}
.ant-divider-vertical {
  border-left: 1px solid #fff;
  height:100%;
}
`;

const ChatName = styled.div`
color:white;
${p => p.active && `border-radius: 2px;
background: #404248;`}
${p => p.isPlatform && `color: #F72375;`}
padding:4px;
margin:4px 0px;
`;

const MessagesCount = styled.div`
height:32px;
line-height:32px;
font-family: SF Pro Display;
font-size: 14px;
text-align:center;
background: #2B2D31;
`;


export default ChatBot;





