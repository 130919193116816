import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";


const PublicNavBar = ({activeTab, isDarkMode, history, currentMatch}) => {
  const toLogin = () => {
    if(currentMatch){
      const {matchId, fornite_username} = currentMatch;
      history.push(`/login?match=${matchId}&streamer=${fornite_username}`)
    }else {
      history.push("/login");
    }
  };
  const toShoppingEvents = () => {
    history.push("/");
  };

  const toCurrentDeal = () => {
    history.push("/liveShoppingGames")
  }
  const toSignUp = () => {
    if(currentMatch){
      const {matchId, fornite_username} = currentMatch;
      history.push(`/signup?match=${matchId}&streamer=${fornite_username}`)
    }else {
      history.push("/signup");
    }
  };

  const toIndex = () => {
    history.push("/");
  };

  const toQuest = () => {
    history.push("/quest")
  }



  return (
    <Container isDarkMode={isDarkMode}>
      <Left>
        <Logo onClick={toIndex} src="../assets/aq_logo.png">
        </Logo>
        <NavButton onClick={toQuest} isActive={activeTab === 'quest'} isDarkMode={isDarkMode}>Quest</NavButton>
        <NavButton onClick={toShoppingEvents} isActive={activeTab === 'liveShopping'} isDarkMode={isDarkMode}>Live Shopping</NavButton>
        <NavButton onClick={toCurrentDeal} isActive={activeTab === 'currentDeals'} isDarkMode={isDarkMode}>Current Deals</NavButton>
        {/* <NavButton onClick={toTournament} isActive={activeTab === 'tournament'} isDarkMode={isDarkMode}>Exchange</NavButton> */}
      </Left>
      <Right>
        <GetMore onClick={toLogin}>Login</GetMore>
        <GetMore onClick={toSignUp}>Sign Up</GetMore>
      </Right>
    </Container>
  );
}


const Container = styled.div`
  height: 78px;
  padding: 15px 40px;
  display: flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 7px #57575729;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  ${p => p.isDarkMode && `background: #18181B 0% 0% no-repeat padding-box;`}
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
width: auto;
height: 40px;
margin-right: 48px;
cursor: pointer;
`;

const NavButton = styled.div`
  width: auto;
  margin-right: 24px;
  font: normal normal 600 20px SF PRO, serif;
  color: #484848;
  cursor: pointer;
  ${(props) =>
    props.isDarkMode && `color: #FAFAFA;`}

  ${(props) =>
    props.isActive && `color: #F72375;`}
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  border-left: 2px solid #bfbfbf;
  padding-left: 32px;
`;

const GetMore = styled.div`
  width: 112px;
  height: 32px;
  background: linear-gradient(
    102deg,
    rgba(247, 133, 104, 1) 0%,
    rgba(248, 97, 111, 1) 99%
  );
  border-radius: 7px;
  font: normal normal bold 18px/32px SF PRO, serif;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  margin-right: 32px;
`;

export default withRouter(React.memo(PublicNavBar));
