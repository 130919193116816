import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import PublicNavBar from "../../nav/publicNavBar";
import PrivateNavBar from "../../nav/privateNavBar";
import Firebase from "../../util/firebase";
import axios from "axios";
import * as API from "../../util/api";
import Loading from "../../util/loading";
import ReactPlayer from "react-player";
import { Descriptions, Divider, Tooltip, Carousel } from "antd";
import ISO6391 from 'iso-639-1';
import moment from 'moment'
import { loadStripe } from "@stripe/stripe-js";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {message} from 'antd';
import TwitchStreams from "./component/twitchStreams";
import YoutubeVideos from "./component/youtubeVideos";
import { Mixpanel } from '../../util/mixpanel';
import ChatBot from "../serviceBot";



const GameDetail = ({match, history}) => {
  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);

  const [loading, setLoading] = useState(true);
  const [game, setGame] = useState(null);
  const [streamingDetail, setStreamingDetail] = useState([]);

  const [viewKey, setViewKey] = useState(false);

  useEffect(() => {
    if(game){
      Mixpanel.track("[WEB] View Game Live Shopping Page", {
        game:game?.name,
        gameId:game?.gameId,
        publisher:game?.publisher
      })
    }
  }, [game])

  const getStreamDetail = async (gameId) => {
    try {
      const url =  `${API.GET_TWITCH_STREAMING}?gameId=${gameId}`;
      const res = await axios.get(url);
      if(res.status === 200){
        const {streamingDetail} = res.data;
        setStreamingDetail(streamingDetail);
      }
    }catch(err){
      console.log(err, 'get streaming detail error')
    }
  }

  useEffect(() => { 
    const gameId =  match.params.id;
    let listener = null;
    const fetchGame = async () => {
      listener = Firebase.firestore().collection("partnerGames").doc(gameId).onSnapshot((doc) => {
        const data = doc.data();
        const codesForSaleLength = data.codesForSale ? data.codesForSale.length : 0;
        const availableCodesLength = data.availableCodes ? data.availableCodes.length : 0;
        const obj = {
         ...data,
         gameId: doc.id,
         codesForSale: codesForSaleLength,
         availableCodes: availableCodesLength,
        };
         setGame(obj)
       })
    }
    const getData = async () => {
      await fetchGame()
      await getStreamDetail(gameId);
      setLoading(false);
    }

    getData();

    return () => {
      if(listener){
        listener();
      }
    }
  }, [match]);


  const genreList = (item) => {
    let genres = [];

    if (Array.isArray(item.genre)) {
      genres = item.genre.map((tag) => {
        return tag;
      });
    } else if (typeof item.genre === 'string') {
      genres = [item.genre];
    }
    return genres;
  }
  
  const processToBuyGame = async () => {
    if(loggedIn && profile){
      Mixpanel.track("[WEB] Prcoess to Buy Liveshopping Game", {
        game:game?.name,
        gameId:game?.gameId,
        publisher:game?.publisher
      })
      await loadStripe(process.env.REACT_APP_STRIPE_LIVE_KEY_GAME);
      const { email } = profile;
      const gameId = game.gameId;
      const requestBody = {
        gameId,
        userId,
        email
      }
      const url = API.LIVESHOPPING_GAME_CHECKOUT;
      axios
      .post(url, requestBody)
      .then((res) => {
        const { data } = res;
        const { url } = data;
        window.location.replace(url);
        /* console.log(url) */
      })
      .catch((e) => console.log("Error tokens", e));
    }else {
      history.push(`/login?page=liveShopping&id=${game.gameId}`)
    }
  }

  const purchased = useMemo(() => {
    if(game && userId){
      const purchaseHistory = game?.purchaseHistory??[];
      if(purchaseHistory.some(item => item.userId === userId)){
        return true;
      }
    }
    return false;
  }, [game, userId]);

  const gameRedeemKey = useMemo(() => {
    if(game && userId){
      const purchaseHistory = game?.purchaseHistory??[];
      const order = purchaseHistory.find(item => item.userId === userId)
      if(order){
        return order.code;
      }
    }
    return null;
  }, [game, userId]);

  const discountPercent = useMemo(() => {
    if(game && game.original_price && game.promote_price){
      const diff = game.original_price - game.promote_price;

      const percent = Math.round((diff/game.original_price)*100);
      console.log(percent, 'precent', diff/game.original_price)
      return percent
    }
    return null;
  }, [game]);

  const purchaseAvailable = useMemo(() => {
    if(game && game.codesForSale > 0){
      return true;
    }
    return false;
  }, [game])

  return <Container>
    {loggedIn ? <PrivateNavBar isDarkMode={true} activeTab="currentDeals"/> : <PublicNavBar isDarkMode={true} activeTab="currentDeals"/>}
    {
      (loading || !game) ? <Content>
        <Loading />
        </Content> :<Content>
        <Top>
          <img src="/assets/back.svg" alt="back" onClick={() => {
            history.push(`/`)
          }} style={{cursor:'pointer', marginRight:12}}/> BACK TO ALL GAMES
        </Top>
          <Title>
            {game?.name}
          </Title>
          <div style={{width:'100%', display:'flex', justifyContent:'space-between', marginTop:24}}>
            <div style={{ display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
              {
                streamingDetail?.length > 0 &&  <TwitchStreams streamingDetail={streamingDetail} />
              }
              {
                streamingDetail?.length === 0 && game.youtubeVideos && <YoutubeVideos videos={game.youtubeVideos} />
              }
             <SubTitle style={{margin:'24px 0px'}}>
                Game Details
              </SubTitle>
      <div style={{width:'944px'}}>
      <Carousel autoplay>
      {
        game.productImages.map((item) => {
          return <img src={item} style={{width:944, /* height:606 */}} alt="gameImage"/>
        })
      }
    </Carousel>
    <Description style={{margin:'24px 0px'}}>
      {game.long_description}
    </Description>
      </div>
            </div>
            <Right style={{width:'274px', display:'flex', flexDirection:'column'}}>
                <Description>
                  {game?.short_description}
                </Description>
                <div style={{display:'flex', margin:'16px 0px'}}>
                <span style={{textDecoration:'line-through', display:'inline-block', marginRight:12, fontSize:16,
                 color: "rgba(255, 255, 255, 0.73)"
                }}>
                 ${game?.original_price}
                  </span> 
                  <Price>
                  ${game?.promote_price}
                  </Price>
                  <Discount>
                    -{discountPercent} %
                  </Discount>
                </div>
               
                {
                  purchased ? <ViewButton onClick={() => {
                    setViewKey(prev => !prev)
                  }}>
                    {
                      viewKey ? 'Reveal Key' : 'View Key'
                    }
                  </ViewButton> : <BuyButton onClick={() => {
                  if(purchaseAvailable){
                    processToBuyGame()
                  }
                }}
                disabled={game.codesForSale <= 0 }
                >
                  {
                    !loggedIn && `Log In to Buy`
                  }
                  {
                    loggedIn && purchaseAvailable && `Buy Now`
                  }
                  {
                    loggedIn && !purchaseAvailable && `Sold Out`
                  }
                </BuyButton>
                }
                {
                  viewKey && gameRedeemKey && <CopyToClipboard
                  text={gameRedeemKey}
                  onCopy={() =>
                    message.success("Code is copied")
                  }
                >
           <KeyBox>
            {gameRedeemKey} <img src="/assets/copy.svg" alt="back" style={{marginLeft:12, cursor:'pointer'}}/>
        </KeyBox >
         </CopyToClipboard>
                }
                <Divider />
                <Detail>
                  <DetailTitle>
                    Publisher
                  </DetailTitle>
                  <DetailContent>
                    {game?.publisher}
                  </DetailContent>
                </Detail>
                <Divider />
                <Detail>
                  <DetailTitle>
                    Genres
                  </DetailTitle>
                  <DetailContent>
                    {game && genreList(game).map(tag => <span style={{marginRight:4}}>
                      {tag}
                    </span>)}
                  </DetailContent>
                </Detail>
                <Divider />
                <Detail>
                  <DetailTitle>
                    Date Created
                  </DetailTitle>
                  <DetailContent>
                    {moment(game?.createdDate).format("MMMM Do, YYYY")}
                  </DetailContent>
                </Detail>
                <Divider />
              </Right>
          </div>
      </Content>
    }
    <ChatBot />
  </Container>
}

const Container = styled.div`
  width: 100%;
  background:#1C1D20 0% 0% no-repeat padding-box;
  min-height:calc(100vh);
  padding-top:78px;
  overflow-y:auto;
  position:relative;

  .ant-divider-horizontal {
    border-top: 1px solid #F72375;
  }
`;

const Top = styled.div`
color: #F72375;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: uppercase;
margin-bottom:16px;
`;

const Right = styled.div`
.ant-divider-horizontal {
  border-top: 1px solid rgba(255, 255, 255, 0.29);
}
`;

const Content = styled.div`
width:1260px;
margin:24px auto 48px;
`;

const Title = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 36px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;

const SubTitle = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 26px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-decoration-line: underline;
`;

const StreamTitle = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin:16px 0px;

display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit the text to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; /* Display "..." for overflow text */
  white-space: normal; /* Ensure text wraps within the two lines */
`;

const StreamerName = styled.div`
color: #F72375;
font-family: SF Pro Display;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-decoration-line: underline;
margin-bottom:12px;
`;

const Language = styled.div`
width:auto;
padding: 3px 12px;
/* align-items: flex-start;
gap: 10px;
width:auto; */

color: #FFF;
font-family: SF Pro Display;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: normal;

border-radius: 100px;
background: #000;
`;

const Description = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
`;

const Price = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
`;

const BuyButton = styled.div`
display: flex;
width: 274px;
padding: 16px 10px 16px 16px;
justify-content: center;
align-items: center;
gap: 16px;

border-radius: 8px;
background: #F72375;

color: #FAFAFA;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
cursor:pointer;

${(p) =>
  p.disabled
    && `background: #B7B7B7 0% 0% no-repeat padding-box; cursor: not-allowed;`
}
`;

const Detail = styled.div`
display:flex;
width:100%;
justify-content:space-between;
`;

const DetailTitle = styled.div`
color: rgba(255, 255, 255, 0.80);
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

const DetailContent = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
`;

const ViewButton = styled.div`
display: flex;
width: 274px;
padding: 16px 10px 16px 16px;
justify-content: center;
align-items: center;
gap: 16px;
border-radius: 8px;
border: 1px solid #F72375;

color: #F72375;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;

cursor:pointer;
`;

const KeyBox = styled.div`
border-radius: 10px;
background: #000;
width: 274px;
height: 44px;
flex-shrink: 0;

color: #FFF;
font-family: SF Pro Display;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 44px;
text-align:center;
margin:16px 0px;
`;

const Discount = styled.div`
display: inline-flex;
padding: 2px 8px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 5px;
background: #F72375;
margin-left:16px;

color: #FFF;
text-align: center;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;


export default withRouter(GameDetail)