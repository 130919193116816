import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ChangePasswordModal from "./component/changePasswordModal";
import ChangeNameModal from "./component/changeNameModal";
import ReverifyModal from "./component/reverifyModal";

//firebase
import Firebase from "../util/firebase";
import * as API from "../util/api";
import axios from "axios";
import Loading from "../util/loading";
import { setVerifyFrom } from "../action/user";

class PersonalInformation extends Component {
  state = {
    resetModalOpen: false,
    reverifyModalOpen: false,
    changeNameOpen:false,
    epicName: "",
    loading: false,
    fetchError:false,
    show:'basic'
  };

  async componentDidMount() {
    const { user } = this.props;
    if (user.loggedIn) {
      const { profile } = user;
      const { savedEpicId } = profile;
      if (savedEpicId) {
        this.setState({
          loading: true,
        });
        try {
        const url = API.FETCH_USER_EPICNAME;
        const requestBody = {
          epicId: savedEpicId,
        };
        const res = await axios.post(url, requestBody);
        if (res.status === 200) {
          this.setState({
            epicName: res.data.epicName,
            loading: false
          });
        }else {
          this.setState({
            fetchError:true,
            loading: false,
          });
        }
        }catch(err){
          this.setState({
            fetchError:true,
            loading: false,
          });
        }
      }
    }
  }

  resetPassword = () => {
    const { user } = this.props;
    const { email } = user.profile;
    let me = this;
    const auth = Firebase.auth();
    auth
      .sendPasswordResetEmail(email)
      .then(function () {
        me.setState({
          resetModalOpen: true,
        });
      })
      .catch(function (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      });
  };

  closeResetModal = () => {
    this.setState({
      resetModalOpen: false,
    });
  };

  verifyAccount = () => {
    const { history } = this.props;
    this.props.setVerifyFrom({
      target:"account",
      target_matchId:null
    });
    history.push("/linkAccount");
  };

  reverify = () => {
    this.setState({
      reverifyModalOpen: true,
    });
  };

  confirmUnlink = async () => {
    const { user } = this.props;
    const { id } = user;
    const url = API.UNLINK_EPICACCOUNT;
    const requestBody = {
      userId: id,
    };
    try {
      const res = await axios.post(url, requestBody);
      if (res.status === 200) {
        this.setState({
          reverifyModalOpen: false,
        });
        this.verifyAccount();
      } else {
        toast.error(
          "Failed to unlink your epic account, please try again or contact support",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          }
        );
      }
    } catch (err) {
      toast.error(
        "Failed to unlink your epic account, please try again or contact support",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        }
      );
    }
  };

  render() {
    const { user } = this.props;
    const { profile } = user;
    const first_name = profile?.first_name ?? "";
    const permission_level = profile?.permission_level ?? 0;
    const twitch_url = profile?.twitch_url ?? 0;
    const email = profile?.email ?? "";
    const founderBadeg = profile?.founderBadeg ?? false;
    let url = "";
    if (twitch_url) {
      if (twitch_url.length < 30) {
        url = twitch_url;
      } else {
        url = twitch_url.substring(0, 45) + "...";
      }
    }
    let accountVerified = false;
    if (profile.verify_status === 2 && profile.savedEpicId) {
      accountVerified = true;
    }

    const count = founderBadeg ? 1 : 0;

    return this.state.loading ? (
      <Container>
        <Loading />
      </Container>
    ) : (
      <Container>
        {
          this.state.show === 'basic' ? <>
              <Title>Personal Information</Title>
        <Content>
          <Top>
          <SubTitle>Profile</SubTitle>
         {/*  <div
                    style={{
                      color: "#EE486D",
                      cursor: "pointer",
                    }}
                    onClick={() => this.setState({
                      show:'profile'
                    })}
                  >
                    View Profile
                  </div> */}
          </Top>
          <Profile>
            <Item style={{ borderBottom: "1px solid #70707052" }}>
              <ItemTitle>Username</ItemTitle>
              <ItemContent>
                <div style={{color:'white'}}>{first_name}</div>
                <div
                    style={{
                      color: "#EE486D",
                      marginRight: 4,
                      cursor: "pointer",
                    }}
                    onClick={() => this.setState({
                      changeNameOpen:true
                    })}
                  >
                    Change Username
                  </div>
              </ItemContent>
            </Item>
            <Item style={{ borderBottom: "1px solid #70707052" }}>
              <ItemTitle>Epic Account</ItemTitle>
              {accountVerified ? (
                <ItemContent>
                  <div style={{color:'white'}}>{this.state.epicName}</div>
                  <div
                    style={{
                      color: "#EE486D",
                      marginRight: 4,
                      cursor: "pointer",
                    }}
                    onClick={this.reverify}
                  >
                    Change Account
                  </div>
                </ItemContent>
              ) : (
                <ItemContent>
                  <span
                    style={{
                      color: "#EE486D",
                      marginRight: 4,
                      cursor: "pointer",
                    }}
                    onClick={this.verifyAccount}
                  >
                    Verify Epic Account
                  </span>
                </ItemContent>
              )}
            </Item>
          </Profile>
          <SubTitle>Contact & Security</SubTitle>
          <Profile>
            <Item style={{ borderBottom: "1px solid #70707052" }}>
              <ItemTitle>Email</ItemTitle>
              <ItemContent style={{color:'white'}}>{email}</ItemContent>
            </Item>
            <Item>
              <ItemTitle>Password</ItemTitle>
              <ItemContent>
                <div style={{color:'white'}}>Create a strong password to improve your security.</div>
                <div
                  style={{
                    color: "#EE486D",
                    marginRight: 4,
                    cursor: "pointer",
                    
                  }}
                  onClick={this.resetPassword}
                >
                  Change Password
                </div>
              </ItemContent>
            </Item>
          </Profile>
          <SubTitle>Badges</SubTitle>
          <BadgeContainer>
            {founderBadeg ? (
              <FounderBadge>
                <img
                  src="../assets/founder-badge.svg"
                  alt=""
                  style={{
                    width: 57,
                    height: 57,
                    marginBottom: 4,
                  }}
                />
                <span style={{ font: "normal normal bold 12px SF PRO, serif" }}>
                  Founder
                </span>
              </FounderBadge>
            ) : (
              <Badge></Badge>
            )}
            <Badge></Badge>
            <Badge></Badge>
            <Badge></Badge>
            <Badge></Badge>
            <Badge></Badge>
            <Badge></Badge>
            <Badge></Badge>
            <Badge></Badge>
            <Badge></Badge>
            <BadgeCount style={{color:'white'}}>{count}/10</BadgeCount>
          </BadgeContainer>
        </Content>
          </>:<>
          <ProfileContainer>
            <TopBack>
            <BackButton onClick={() => {
            this.setState({
              show:'basic'
            })
          }}>
            Back
            </BackButton>
            </TopBack>
        </ProfileContainer>
        </>
        }
        <ChangePasswordModal
        open={this.state.resetModalOpen}
        closeModal={this.closeResetModal}
      />
      <ChangeNameModal 
       open={this.state.changeNameOpen}
       closeModal={() => this.setState({
         changeNameOpen:false
       })}
      />
      <ReverifyModal
        open={this.state.reverifyModalOpen}
        closeModal={() =>
          this.setState({
            reverifyModalOpen: false,
          })
        }
        confirmUnlink={this.confirmUnlink}
      />
      </Container>
    );
  }
}

const TopBack = styled.div`
width:100%;
padding:24px 24px 0px 24px;
margin:24px 0 12px 0px;
display:flex;
justify-content:flex-end;
`;

const BackButton = styled.div`
  width: 122px;
  height: 41px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 16px 16px #00000029;
  border-radius: 11px;
  font: normal normal 600 24px/41px SF PRO, serif;
  letter-spacing: 0px;
  color: #484848;
  text-align: center;
  cursor: pointer;
`;

const Top = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
`;

const Container = styled.div`
  width: 726px;
  @media (min-width: 1480px) {
    width: 826px;
  }
`;

const ProfileContainer = styled.div`
  width: 836px;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  @media (min-width: 1480px) {
    width: 936px;
  }
`;

const Title = styled.span`
  display: block;
  text-align: left;
  font: normal normal bold 18px/21px SF PRO, serif;
  letter-spacing: 0px;
  color: #ee486d;
  margin-bottom: 43px;
`;

const Content = styled.div`
  border-top: 1px solid #7b7a7a;
  padding-top: 48px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 96px;
`;

const SubTitle = styled.div`
  text-align: left;
  font: normal normal 600 17px/53px SF PRO, serif;
  letter-spacing: 0px;
  color: white;
`;

const Profile = styled.div`
  height: auto;
  width: 100%;
  background: #000 0% 0% no-repeat padding-box;
  border: 1px solid #70707052;
  border-radius: 5px;
  margin-bottom: 48px;
`;

const Item = styled.div`
  display: flex;
  height: 91px;
  padding: 0px 24px;
  align-items: center;
  justify-content: space-between;
`;

const ItemTitle = styled.div`
  text-align: left;
  font: normal normal bold 18px SF PRO, serif;
  letter-spacing: 0px;
  color: #FFFFFF;
`;

const ItemContent = styled.div`
  background: #efeff16b 0% 0% no-repeat padding-box;
  border-radius: 2px;
  width: 486px;
  min-height: 36px;
  text-align: left;
  font: normal normal normal 15px SF PRO, serif;
  letter-spacing: 0px;
  color: #000000;
  line-height: 36px;
  padding: 0px 16px;
  display:flex;
  justify-content:space-between;
`;

const BadgeContainer = styled.div`
  width: 726px;
  height: 304px;
  background: #000 0% 0% no-repeat padding-box;
  border: 1px solid #70707052;
  border-radius: 5px;
  padding: 24px 0px 24px 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  @media (min-width: 1480px) {
    width: 826px;
  }
`;

const Badge = styled.div`
  background: #fefefe 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  width: 104px;
  height: 94px;
  margin-right: 24px;
  @media (min-width: 1480px) {
    width: 124px;
  }
`;

const FounderBadge = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #fed100;
  border-radius: 5px;
  width: 104px;
  height: 94px;
  margin-right: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 1480px) {
    width: 124px;
  }
`;

const BadgeCount = styled.div`
  position: absolute;
  bottom: 12px;
  right: 24px;
  font: normal normal bold 15px/18px SF PRO, serif;
  letter-spacing: 0px;
  color: #000000;
`;

const mapStateToProps = ({ user, matches }) => ({ user, matches });

const mapDispatchToProps = {
  setVerifyFrom
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PersonalInformation));


