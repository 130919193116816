import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import { Menu, Dropdown, Popover, Skeleton, List, Empty} from "antd";
import { logout } from "../action/user";
import * as API from "../util/api";
import axios from "axios";
import {
  BellOutlined, AreaChartOutlined
} from '@ant-design/icons';
import VirtualList from 'rc-virtual-list';
import moment from "moment";

class PrivateNavBar extends Component {
  state = {
    notifications:[],
    loading:true
  };


  fetchNotifications = async () => {
    const { user } = this.props;
    const {id} = user;
    try {
      const url = `${API.FETCH_NOTIFICATION}/${id}`;
      const res = await axios.get(url);
      const notifications = res.data;
      const showNotifications = notifications.reverse();
      this.setState({
        notifications:showNotifications,
        loading:false
      })
    }catch(err){
      console.log(err);
    }
  }

  toMyProfile = () => {
    const { user, history } = this.props;
    const {id} = user;
    history.push(`/profile/${id}`);
  }

  toPrize = () => {
    const { history } = this.props;
    history.push("/");
  };

  toProfile = () => {
    const { history, user } = this.props;
    if (user.loggedIn) {
      history.push("/myAccount/information");
    } else {
      history.push("/login");
    }
  };

  toIndex = () => {
    const { history } = this.props;
    history.push("/");
  };

  toCurrentDeal = () => {
    const { history } = this.props;
    history.push("/liveShoppingGames")
  };


  toAllMatch = () => {
    const { history } = this.props;
    history.push("/allMatches");
  };
  toGetMore = () => {
    const { history } = this.props;
    history.push("/multiplier");
  };

  toCreateMatch = () => {
    const { history } = this.props;
    history.push("/dashboard/create");
  };

  toDashBoard = () => {
    const { history } = this.props;
    history.push("/dashboard");
  };

  toModerator = () => {
    const {history} = this.props;
    history.push('/moderator')
  }

  toQuest = () => {
    const {history} = this.props;
    history.push("/quest")
  }

  toAdmin = () => {
    window.open("https://arcadequestadmin.onrender.com")
  }

  toInvestment = () => {
    const {history} = this.props;
    history.push("/investments")
  }


  logout = () => {
    this.props.logout();
    const { history } = this.props;
    history.push("/");
  };

  toLeaderboard = () => {
    const { history } = this.props;
    history.push("/leaderboard");
  }

  toExchange = () => {
    const {history} = this.props;
    history.push("/exchange");
  }

  toBrandAdmin = () => {
    window.open("https://game.arcadequest.app/")
  }

  toInfluencerAdmin = () => {
    window.open("https://club.arcadequest.app/")
  }

  toTournament = () => {
    const {history} = this.props;
    history.push("/tournaments")
  }

  toTournamentAdmin = () => {
    const {history} = this.props;
    history.push("/tournament_admin/tournaments")
  }

  render() {
    const { user, activeTab, isDarkMode, history } = this.props;

    const permission_level = user.profile?.permission_level ?? 0;
    const token_count = user.profile?.token_count ?? 0;
    const isMod = user.profile?.streamerList && user.profile?.streamerList?.length > 0;

    const menu = (
      <StyledMenu>
        <Menu.Item
          onClick={this.toProfile}
        >
          <MenuBox>
          <Item>My Account</Item>
          <img
            src="/assets/nav-profile.png"
            alt=""
            style={{
              height: "20px",
              width: "20px",

            }}
          />
          </MenuBox>
          
        </Menu.Item>
        {(permission_level === 1 || permission_level === 2) && (
          <Menu.Item
            onClick={this.toDashBoard}
          >
            <MenuBox>
            <Item>Dashboard</Item>
            <img
              src="/assets/nav-dashboard.png"
              alt=""
              style={{
                height: "20px",
                width: "20px",

              }}
            />
            </MenuBox>
          </Menu.Item>
        )}
        {permission_level === 2 && (
          <Menu.Item
            onClick={this.toTournamentAdmin}
          >
            <MenuBox>
            <Item>Tournament Admin</Item>
            <AreaChartOutlined  style={{
              fontSize:20
            }}/>
            </MenuBox>
          </Menu.Item>
        )}
        <Menu.Item
          onClick={this.logout}
        >
          <MenuBox>
          <Item>Logout</Item>
          <img
            src="/assets/nav-logout.png"
            alt=""
            style={{
              height: "20px",
              width: "20px",

            }}
          />
          </MenuBox>
        </Menu.Item>
      </StyledMenu>
    );

    const content = (
      <NotificationBox>
        {
          this.state.loading && <Skeleton />
        }
        {
          !this.state.loading && this.state.notifications.length >0 && <List>
          <VirtualList
            data={this.state.notifications}
            height={300}
            itemHeight={60}
          >
            {(item) => (
              <List.Item style={{borderBottom:'1px solid #E5E5E5'}}>
                <List.Item.Meta
                  title={<NotifiTitle>
                    <div>{item.title}</div>
                    <div>{moment(item.createdAt).format("MMM Do")}</div>
                  </NotifiTitle>}
                  description={<Message>{item.message}</Message>}
                />
              </List.Item>
            )}
          </VirtualList>
        </List>
        }
        {
          !this.state.loading && this.state.notifications.length === 0 && <Empty />
        }
      </NotificationBox>
    );

    const title = (
      <Title>
        Notifications
      </Title>
    )

    return (
      <Container isDarkMode={isDarkMode}>
        <Left>
          <Logo onClick={this.toIndex} src="/assets/aq_logo.png">
            {/* <img
              src="/assets/aq_logo.png"
              style={{ height: "45px" }}
              alt="logo"
            /> */}
          </Logo>
          <NavButton onClick={this.toQuest} isActive={activeTab === 'quest'} isDarkMode={isDarkMode}>Quest</NavButton>
          <NavButton onClick={this.toPrize} isActive={activeTab === 'liveShopping'} isDarkMode={isDarkMode}>Live Shopping</NavButton>
          <NavButton onClick={this.toCurrentDeal} isActive={activeTab === 'currentDeals'} isDarkMode={isDarkMode}>Current Deals</NavButton>
          <NavButton onClick={this.toExchange} isActive={activeTab === 'exchange'} isDarkMode={isDarkMode}>Exchange</NavButton>
          {/* <NavButton onClick={this.toTournament} isActive={activeTab === 'tournament'} isDarkMode={isDarkMode}>Tournament</NavButton> */}
          <NavButton onClick={this.toMyProfile} isActive={activeTab === 'profile'} isDarkMode={isDarkMode}>My Profile</NavButton>
          {/* {permission_level !== 0 && (
            <NavButton
              style={{ color: "#F72375" }}
              onClick={this.toCreateMatch}
            >
              Create Match
            </NavButton>
          )} */}
        </Left>
        <Right>
          <NotificationContainer>
          <Popover placement="bottom" title={title} content={content} trigger="hover" 
          onVisibleChange={(visible) => {
            if(visible){
              this.fetchNotifications()
            }else {
              this.setState({ 
                loading:true
              })
            }
          }}
          >
          <BellOutlined style={{fontSize:20, cursor:"pointer"}}/>
      </Popover>
          </NotificationContainer>
          
          <GetMore onClick={this.toGetMore}>Premium</GetMore>
          <TokenCount isDarkMode={isDarkMode}>
            <img
              src="/assets/ic_mytokens.png"
              alt=""
              style={{
                height: "16px",
                width: "20px",
                marginRight: "8px",
              }}
            />
            {token_count}
          </TokenCount>
          <Dropdown
            overlay={menu}
            placement="bottomLeft"
            onClick={(e) => e.preventDefault()}
            trigger={["click"]}
          >
            <Profile isDarkMode={isDarkMode} />
          </Dropdown>
        </Right>
      </Container>
    );
  }
}

const MenuBox = styled.div`
width: 225px;
height: 54px;
display: flex;
align-items: center;
justify-content: space-between;
padding:0 32px 0 16px;
`;

const NotifiTitle = styled.div`
display:flex;
width:100%;
justify-content:space-between;
font: normal normal bold 14px SF PRO, serif;
margin-bottom:8px;
padding-right:12px;
`;

const Message = styled.div`
font: normal normal bold 14px SF PRO, serif;
padding-right:12px;
width:100%;
`;

const Title = styled.div`
width:300px;
height:36px;
font: normal normal bold 18px/36px SF PRO, serif;
`;

const StyledMenu = styled(Menu)`
  width: 245px;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  padding: 8px 8px;
`;

const Item = styled.div`
  font: normal normal 600 18px SF PRO, serif;
  letter-spacing: 0px;
  color: #484848;
`;

const Container = styled.div`
  height: 78px;
  padding: 15px 40px;
  display: flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 7px #57575729;
  justify-content: space-between;
  align-items:center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  ${p => p.isDarkMode && `background: #18181B 0% 0% no-repeat padding-box;`}
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: auto;
  height: 40px;
  margin-right: 48px;
  cursor: pointer;
`;

const NavButton = styled.div`
  width: auto;
  margin-right: 24px;
  font: normal normal 600 20px SF PRO, serif;
  color: #484848;
  cursor: pointer;
  ${(props) =>
    props.isDarkMode && `color: #FAFAFA;`}

  ${(props) =>
    props.isActive && `color: #F72375;`}
`;

const Profile = styled.img`
  background: url("/assets/adaptive-icon-large.png") no-repeat center center;
  background-size: cover;
  border-radius: 50%;
  width: 47px;
  height: 47px;
  cursor: pointer;
  ${(props) =>
    props.isDarkMode && `border:1px solid #18181B`}
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  border-left: 2px solid #bfbfbf;
  padding-left: 32px;
`;

const GetMore = styled.div`
  width: 132px;
  height: 32px;
  background: linear-gradient(
    102deg,
    rgba(247, 133, 104, 1) 0%,
    rgba(248, 97, 111, 1) 99%
  );
  border-radius: 7px;
  font: normal normal bold 18px/32px SF PRO, serif;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  margin-right: 32px;
`;

const TokenCount = styled.div`
  font: normal normal bold 16px SF PRO, serif;
  letter-spacing: 0px;
  color: #484848;
  margin-right: 32px;
  ${(props) =>
    props.isDarkMode && `color: #FAFAFA;`}
`;

const NotificationContainer = styled.div`
border-radius: 50%;
width: 47px;
height: 47px;
margin-right:16px;
background-color:#E5E5E5;
display:flex;
align-items:center;
justify-content:center;
`;

const NotificationBox = styled.div`
height:320px;
overflow-y:auto;
`;



const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = {
  logout,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateNavBar));

