import React, {useMemo, useState, useEffect} from "react";
import styled from "styled-components";
import Firebase from "../util/firebase";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { Divider, message, List } from "antd";
import moment from 'moment'
import { CopyToClipboard } from "react-copy-to-clipboard";

const Orders = () => {
  const [orders, setOrders] = useState([]);

  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);

  useEffect(() => {
    const fetchOrders = async () => {
      const snapshot = await Firebase.firestore()
        .collection("liveShoppingOrder")
        .where("userId", "==", userId)
        .get();

    if(!snapshot.empty) {
      const result = []
      snapshot.forEach((doc) => {
        const match = {
          ...doc.data(),
          orderId: doc.id,
        };
        result.push(match);
      });
      setOrders(result)
      }
    }

    fetchOrders()

  }, [userId]);

  const fulfilledOrders = useMemo(() => {
    return orders.filter(item => item.status === 1)
  }, [orders])

  return <Container>
    <Title>
      My Orders
    </Title>
    <Divider />
    <List
              dataSource={fulfilledOrders}
              renderItem={(item) => (
                <List.Item key={item.id}>
                  <OrderContainer>
            <div>
              <Name style={{marginBottom:12}}>
                {item.gameName}
              </Name>
              <Date>
                {moment(item.createdAt).format("L")}
              </Date>
            </div>
          <Code>
            {item.code}
          </Code>
          <CopyToClipboard
                  text={item?.code}
                  onCopy={() =>
                    message.success("Code is copied")
                  }
                >
             <img src="/assets/copy.svg" alt="back" style={{marginLeft:12, cursor:'pointer'}}/>
         </CopyToClipboard>
          </OrderContainer>
                </List.Item>
              )}
            ></List>
  </Container>
}

const Container = styled.div`
.ant-divider-horizontal {
  border-top: 2px solid rgba(255, 255, 255, 0.29);
  margin:24px 0px 0px 0px;
}
width:680px;
`;


const Title = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;


const OrderContainer = styled.div`
border-bottom: 2px solid rgba(255, 255, 255, 0.29);
display:flex;
width:100%;
padding:12px 0px;
align-items:center;
`;

const Name = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
`;

const Date = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

const Code = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left:auto;
`;

export default withRouter(Orders)