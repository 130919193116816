import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import PublicNavBar from "../../nav/publicNavBar";
import PrivateNavBar from "../../nav/privateNavBar";
import Firebase from "../../util/firebase";
import CurrentEvents from "./currentEvents";
import ChatBot from "../serviceBot";

const LiveShoppingEvents = ({history}) => {
  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const listener = Firebase.firestore()
    .collection("liveShoppingEvent")
    .where('status', 'in', [0,1])
    .onSnapshot(querySnapshot => {
      let list = [];
      querySnapshot.forEach(function (doc) {
        const obj = {
          ...doc.data(),
         eventId: doc.id,
        };
        list.push(obj)
      });
      setEvents(list)
    })
    return () => {
      if(listener){
        listener();
      }
    }
  }, [])
  return <Container>
    {loggedIn ? <PrivateNavBar isDarkMode={true} activeTab="liveShopping"/> : <PublicNavBar isDarkMode={true} activeTab="liveShopping"/>}
    <Top>
        <TopText>
          <Title>
          Drops
          </Title>
          <TopContext>
          Get awesome deals on exclusive deals on high quality indie games. Each drop features a series of indie games that you can get at a bigger discount here than anywhere else.
          </TopContext>
          <ViewButton onClick={() => {
            history.push("/liveShoppingGames")
          }}>
            View Current Deals
          </ViewButton>
        </TopText>
      </Top>
    <Content>
      <CurrentEvents events={events} />
    </Content>
    <ChatBot />
  </Container>
}

const Container = styled.div`
  width: 100%;
  background:#1C1D20 0% 0% no-repeat padding-box;
  min-height:calc(100vh);
  padding-top:78px;
  overflow-y:auto;
  position:relative;
`;

const Content = styled.div`
width:1260px;
margin:0px auto 48px;
`;
const Top = styled.div`
width: 100%;
height: 261px;
flex-shrink: 0;
margin-bottom:36px;
background-image: url("/assets/liveShopping.png");
background-size: cover;
background-position: center;
border: 1px solid transparent; 
`;

const TopText = styled.div`
width:1260px;
margin:36px auto;
`;

const TopContext = styled.div`
color: #FFF;
font-family: SF Pro;
font-size: 16px;
font-style: normal;
font-weight: 590;
line-height: normal;
width:400px;
margin-top:24px;
`;

const Title = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
`;

const ViewButton = styled.div`
margin-top:16px;
display: flex;
width: 274px;
padding: 16px 10px 16px 16px;
justify-content: center;
align-items: center;
gap: 16px;
border-radius: 8px;
border: 1px solid #F72375;

color: #F72375;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;

cursor:pointer;
`;

export default withRouter(LiveShoppingEvents)