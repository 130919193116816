import React from 'react'
import styled from "styled-components";

const GameCard = ({game, status}) => {
  const genreList = (item) => {
    let genres = [];

    if (Array.isArray(item.genre)) {
      genres = item.genre.map((tag) => {
        console.log(tag);
        return tag;
      });
    } else if (typeof item.genre === 'string') {
      genres = [item.genre];
    }
    return genres;
  }

  return <Container status={status}>
    <img src={game.bannerUrl} style={{
      width:145,
      /* height:105 */
    }} alt="banner"/>
    <div style={{marginLeft:'24px', display:'flex', flexDirection:'column'}}>
      <Title>
        {game.name}
      </Title>
      <div style={{display:'flex'}}>
      {
              genreList(game).map((tag) => {  
                return <Tag>
                  {tag}
                </Tag>
              })
    }
      </div>
      <div style={{marginTop:'auto', display:'flex', alignItems:'center'}}>
        <TBA>
          TBA
        </TBA>
        {
          game.original_price && <Price>
            US${game.original_price}
          </Price>
        }
      </div>
    </div>
  </Container>
}

const Container = styled.div`
width: 412px;
height: 144px;
border: 1px solid #616161;
padding:16px;
margin-bottom:12px;
display:flex;
`;

const Title = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-align:left;
margin-bottom:8px;
`;

const Tag = styled.div`
display: flex;
padding: 2px 8px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 8px;
background: #000;
color: #FFF;
font-family: SF Pro Display;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
margin-right:8px;
`;

const TBA = styled.div`
display: flex;
width: 37px;
height: 22px;
padding: 3px 6px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 2px;
background: rgba(255, 93, 170, 0.53);
color: #FFF;
font-family: SF Pro Display;
font-size: 8px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-right:12px;
`;

const Price = styled.div`
color: #D9D9D9;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration:line-through;
`;

export default GameCard;